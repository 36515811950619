<template>
  <BaseNavItem
    :title="$t('labels.transactions')"
    :icon="$vuetify.icons.values.transactions"
    :route="targetRoute"
    :visible="visible"
  />
</template>

<script>
import { mapState } from 'vuex'
import { BaseNavItem } from '@argon/app/components'
import transactionPerimeter from '../perimeters'

export default {
  name: 'TransitionsNavItem',
  components: { BaseNavItem },
  perimeters: [transactionPerimeter],
  computed: {
    targetRoute() {
      return {
        name: 'transactions',
        params: this.$route.params
      }
    },
    visible() {
      return this.$transaction.isAllowed('atLeastManager')
    }
  }
}
</script>
